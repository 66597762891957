.profile-picture {
    cursor: pointer;

    width: 150px;
    height: 150px;

    &.thumbnail {
        width: 40px !important;
        height: 40px !important;
    }

    &.round {
        border-radius: 100%;
    }
}

.bubble-image {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: 1400px) {
        // width: 75%;
        width: 180px;
        height: 180px;
    }

    @media (max-width: 767px) {
        // width: 50%;
        width: 220px;
        height: 220px;
    }
}